.bluebook-is-teacher-preview:global(+ div .CharmBracelet) {
  @apply m-0 bg-bluebook-gray8;
}

.bluebook-is-teacher-preview:global(+ div .question-list.all-overview-icons) {
  @apply p-0 bg-bluebook-gray8 border-t-0 border-b-[4px] border-bluebook-blue;
}

.bluebook-is-teacher-preview:global(+ div .CharmBracelet .actions) {
  @apply gap-[8px] w-auto mr-[42px] ml-[8px];
}

.bluebook-is-teacher-preview:global(
    + div .CharmBracelet .actions button:not(:disabled)
  ) {
  @apply bg-bluebook-blue;
}

.bluebook-is-teacher-preview:global(
    + div .CharmBracelet .overview-icon-container .overview-icon
  ) {
  @apply cursor-pointer;
}
