.bluebook-tooltip-container {
  @apply !border-none !bg-bluebook-gray1 !px-[16px] !py-[12px] !text-14 !leading-[17.5px] !border-[10px] !rounded-[6px];
  --tooltipBorder: var(--color-bluebook-gray1);
  --tooltipBackground: var(--color-bluebook-gray1);
}

.bluebook-tooltip-arrow {
  --tooltipBorder: var(--color-bluebook-gray1);
  --tooltipBackground: var(--color-bluebook-gray1);
  @apply !-mt-[7px];
}

.bluebook-tooltip-arrow::before {
  @apply !top-0 !border-t-0 !border-r-[7px] !border-b-[7px] !border-l-[7px];
}

.bluebook-tooltip-arrow::after {
  @apply !border-0;
}
