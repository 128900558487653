.bluebook-player
  :global(div.modal-inner-root div.base-modal .modal-max-height) {
  max-height: calc(
    var(--doc-height) - var(--header-height) - var(--footer-height) -
      var(--impersonating-header-height) - var(--teacher-preview-header-height) -
      var(--modal-margins)
  );
}
.bluebook-player :global(.lrn-wysihtml-modal-dialog) {
  position: absolute !important;
  top: calc(50% + 160px) !important;
}

@media (max-width: 768px) {
  .bluebook-player :global(.lrn-wysihtml-modal-dialog) {
    top: 40px !important;
  }
}

.bluebook-player
  :global(
    div.modal-inner-root
      div.z-blueBook_referenceSheet
      div.base-modal
      .modal-max-height
  ) {
  max-height: var(--doc-height);
}
