.tooltip-container {
  align-items: center;
}

.content {
  @apply p-6 desktop:p-8 bg-gray-200;
}

:global(.with-spp-player .learnosity-item .row > .col-xs-12 > div) {
  height: auto !important;
}
