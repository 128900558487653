.bluebook-is-teacher-preview
  :global(.bluebook-player-inner-container .lrn .PlayerRubricsPlaceHolder) {
  @apply my-0 ml-0 pb-0 px-[15px] mr-0 flex justify-center;
}

.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container
      .lrn
      .right-column
      .PlayerRubricsPlaceHolder
      > div
  ) {
  @apply m-0 min-w-full max-w-full;
}

.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container
      .lrn
      .right-column
      .PlayerRubricsPlaceHolder
  ) {
  @apply px-16;
}

.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container
      .lrn
      .lrn-viewport
      .PlayerRubricsPlaceHolder
  ) {
  @apply my-0 p-0 ml-0 mr-2;
}

.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container
      .lrn
      .right-column
      .PlayerRubricsPlaceHolder
  ) {
  @apply mr-0;
}

.bluebook-is-teacher-preview
  :global(.right-column .PlayerRubricsPlaceHolder .LearnosityDistractor) {
  @apply w-full;
}

.bluebook-is-teacher-preview
  :global(.PlayerRubricsPlaceHolder .LearnosityDistractor) {
  @apply bg-bluebook-red2 bg-opacity-10 mx-auto max-w-[664px] border-[2px] border-bluebook-red2 rounded-[1rem] mt-4;
}

.bluebook-is-teacher-preview :global(.LearnosityDistractor.--valid) {
  @apply bg-bluebook-green1 bg-opacity-10  border-bluebook-green1;
}

.bluebook-is-teacher-preview
  :global(.LearnosityDistractor .formatted_line_break) {
  @apply h-[0.5rem] block;
}

.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container
      .PlayerRubricsPlaceHolder
      .LearnosityDistractor
      > .title
  ) {
  @apply font-semibold text-[1.5rem] py-0 border-none;
}

.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container
      .PlayerRubricsPlaceHolder
      .LearnosityDistractor
      > .content
  ) {
  @apply pt-0 px-[24px] pb-[24px];
}

.bluebook-is-teacher-preview :global(.display-correct-answers .lrn-mcq-option),
.bluebook-is-teacher-preview :global(.display-correct-answers .mcq-option) {
  @apply relative w-fit;
}

.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container
      .display-correct-answers
      .lrn-response-validate-wrapper
      li.lrn-mcq-option
  ),
.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container
      .display-correct-answers
      .lrn-response-validate-wrapper
      li.mcq-option
  ) {
  @apply !border-none !bg-none !p-[1px];
}

.bluebook-is-teacher-preview
  :global(
    .display-correct-answers
      .lrn-mcq-option.--is-selected.--correct
      input[type='checkbox']
      + .lrn-label::after
  ),
.bluebook-is-teacher-preview
  :global(
    .display-correct-answers
      .mcq-option.--is-selected.--correct
      input[type='checkbox']
      + .lrn-label::after
  ),
.bluebook-is-teacher-preview
  :global(
    .display-correct-answers
      .lrn-mcq-option.--is-selected.--incorrect
      input[type='checkbox']
      + .lrn-label::after
  ),
.bluebook-is-teacher-preview
  :global(
    .display-correct-answers
      .mcq-option.--is-selected.--incorrect
      input[type='checkbox']
      + .lrn-label::after
  ) {
  @apply !bg-bluebook-gray1 !border-bluebook-gray1 pt-[5px];
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="%23ffffff" version="1.1" width="14px" height="14px" viewBox="0 0 78.369 78.369" xml:space="preserve"%3E%3Cg%3E%3Cpath d="M78.049,19.015L29.458,67.606c-0.428,0.428-1.121,0.428-1.548,0L0.32,40.015c-0.427-0.426-0.427-1.119,0-1.547l6.704-6.704 c0.428-0.427,1.121-0.427,1.548,0l20.113,20.112l41.113-41.113c0.429-0.427,1.12-%0A.0.427,1.548,0l6.703,6.704 C78.477,17.894,78.477,18.586,78.049,19.015z"/%3E%3C/g%3E%3C/svg%3E') !important;
}

.bluebook-is-teacher-preview
  :global(
    .display-correct-answers .lrn-mcq-option.--is-selected.--correct .lrn-label
  ),
.bluebook-is-teacher-preview
  :global(
    .display-correct-answers .mcq-option.--is-selected.--correct .lrn-label
  ) {
  @apply !border-bluebook-green1 shadow-[0_0_0_1px] shadow-bluebook-green1 after:!border-bluebook-green1 after:bg-bluebook-green1 after:text-white;
}

.bluebook-is-teacher-preview
  :global(
    .display-correct-answers
      .lrn-mcq-option.--is-selected.--incorrect
      .lrn-label
  ),
.bluebook-is-teacher-preview
  :global(
    .display-correct-answers .mcq-option.--is-selected.--incorrect .lrn-label
  ) {
  @apply !border-bluebook-red2 shadow-[0_0_0_1px] shadow-bluebook-red2 after:!border-bluebook-red2 after:bg-bluebook-red2 after:text-white;
}

.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container .display-correct-answers .lrn-mcq-option
  ),
.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container .display-correct-answers .mcq-option
  ) {
  @apply after:absolute after:top-1/2 after:right-[10px];
}

.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container .display-correct-answers .mcq-option
  ),
.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container .display-correct-answers .lrn-mcq-option
  ) {
  @apply after:font-[LearnosityIconsRegular] after:text-[0.86em] after:mr-2;
}

.bluebook-is-teacher-preview
  :global(.display-correct-answers .mcq-option.--is-selected.--correct),
.bluebook-is-teacher-preview
  :global(.display-correct-answers .lrn-mcq-option.--is-selected.--correct) {
  @apply after:text-bluebook-green1 after:text-opacity-100 after:content-['\E607'];
}

.bluebook-is-teacher-preview
  :global(.display-correct-answers .mcq-option.--is-selected.--incorrect),
.bluebook-is-teacher-preview
  :global(.display-correct-answers .lrn-mcq-option.--is-selected.--incorrect) {
  @apply after:text-bluebook-red2 after:text-opacity-100 after:content-['\E608'] after:rotate-90;
}

.bluebook-is-teacher-preview
  :global(.lrn .PlayerRubricsPlaceHolder .ScoringLayout) {
  @apply mx-auto max-w-[664px];
}

.bluebook-is-teacher-preview
  :global(.lrn .right-column .PlayerRubricsPlaceHolder .ScoringLayout) {
  @apply mx-0;
}

.bluebook-is-teacher-preview
  :global(.lrn .right-column .PlayerRubricsPlaceHolder .ScoringLayout) {
  @apply w-auto;
}

.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container
      .lrn
      .lrn-viewport
      .right-column
      .PlayerRubricsPlaceHolder
  ) {
  @apply px-16;
}

.bluebook-is-teacher-preview
  :global(.lrn .PlayerRubricsPlaceHolder .ScoringLayout .content .scroll) {
  @apply p-0 overflow-visible;
}

.bluebook-is-teacher-preview
  :global(.lrn .PlayerRubricsPlaceHolder .ScoringLayout .content) {
  @apply m-0;
}

.bluebook-is-teacher-preview
  :global(
    .lrn
      .PlayerRubricsPlaceHolder
      .ScoringLayout
      .content
      .scroll
      .content-item
      .learnosity-item
  ) {
  @apply overflow-visible before:content-none after:content-none;
}

.bluebook-is-teacher-preview
  :global(
    .lrn .PlayerRubricsPlaceHolder .ScoringLayout .content div.scroll div.row
  ) {
  @apply overflow-visible !h-auto;
}

.bluebook-is-teacher-preview
  :global(
    .lrn
      .PlayerRubricsPlaceHolder
      .ScoringLayout
      .content
      .scroll
      .content-item
      .learnosity-item
      .lrn_widget
  ) {
  @apply w-full;
}

.bluebook-is-teacher-preview
  :global(
    .lrn
      .PlayerRubricsPlaceHolder
      .ScoringLayout
      .content
      .scroll
      .content-item
      .learnosity-item
      .lrn_widget
      .lrn_response_wrapper
  ) {
  @apply p-0;
}

.bluebook-is-teacher-preview
  :global(
    .lrn
      .PlayerRubricsPlaceHolder
      .ScoringLayout
      .content
      .scroll
      .content-item
      .learnosity-item
      .lrn_widget
      .lrn_response_wrapper
      .lrn_response.lrn_clearfix
  ) {
  @apply min-w-0;
}

.bluebook-is-teacher-preview
  :global(.lrn .PlayerRubricsPlaceHolder .ScoringLayout) {
  @apply p-6 bg-bluebook-light-gray border-[2px] border-bluebook-gray1 rounded-lg;
}

.bluebook-is-teacher-preview
  :global(
    .bluebook-player-inner-container
      .lrn
      .PlayerRubricsPlaceHolder
      .ScoringLayout
      .content
  ) {
  @apply bg-bluebook-light-gray before:content-none m-0;
}

.bluebook-is-teacher-preview
  :global(
    .lrn
      .PlayerRubricsPlaceHolder
      .ScoringLayout
      .content
      .title-container
      .title
  ),
.bluebook-is-teacher-preview
  :global(
    .lrn .PlayerRubricsPlaceHolder .ScoringLayout .content .scoring-rubrics-tabs
  ) {
  @apply bg-bluebook-light-gray pl-0 shadow-none;
}

.bluebook-is-teacher-preview
  :global(
    .lrn
      .PlayerRubricsPlaceHolder
      .ScoringLayout
      .content
      .title-container
      .title
  ) {
  @apply text-bluebook-gray1 font-semibold;
}

.bluebook-is-teacher-preview
  :global(
    .lrn
      .PlayerRubricsPlaceHolder
      .ScoringLayout
      .content
      .scoring-rubrics-tabs
      .MuiTab-textColorPrimary
  ) {
  @apply border-x-none border-t-none border-b-2 border-bluebook-blue bottom-1;
}

.bluebook-is-teacher-preview
  :global(
    .lrn
      .PlayerRubricsPlaceHolder
      .ScoringLayout
      .content
      .scoring-rubrics-tabs
      .MuiTab-textColorPrimary.Mui-selected
  ) {
  @apply bg-bluebook-blue border-none text-white;
}

.bluebook-is-teacher-preview
  :global(
    .lrn
      .PlayerRubricsPlaceHolder
      .ScoringLayout
      .content
      .scoring-rubrics-tabs
      .MuiTab-textColorPrimary
  ) {
  @apply text-bluebook-gray1;
}

.bluebook-is-teacher-preview
  :global(
    .lrn
      .PlayerRubricsPlaceHolder
      .ScoringLayout
      .content
      .scoring-rubrics-tabs
      .MuiTabs-scrollButtons
  ) {
  @apply text-bluebook-gray1 border-none;
}

.bluebook-is-teacher-preview
  :global(
    .lrn
      .PlayerRubricsPlaceHolder
      .ScoringLayout
      .input-wrapper
      fieldset
      .tabs
      label.option
  ) {
  @apply bg-white border-2 border-bluebook-blue text-bluebook-blue;
}

.bluebook-is-teacher-preview
  :global(
    .lrn
      .PlayerRubricsPlaceHolder
      .ScoringLayout
      .input-wrapper
      fieldset
      .tabs
      .input-for-tabs:checked
      + label.option
  ) {
  @apply text-white border-2 bg-bluebook-blue shadow-none;
}

.bluebook-is-teacher-preview:global(
    + .question-preview-player .ItemMetadata__pane .ItemMetadata__toggle_button
  ) {
  @apply bg-bluebook-gray8;
}

.bluebook-is-teacher-preview:global(
    + .question-preview-player .ItemMetadata__pane .ItemMetadata
  ) {
  @apply bg-bluebook-gray8;
}

.bluebook-is-teacher-preview:global(
    + .question-preview-player .ItemMetadata__pane
  ) {
  @apply bg-bluebook-gray8;
}
