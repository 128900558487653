/* Default highlights colors */
.bluebook-player :global(.lrn-annotations-color--bluebook-red) {
  @apply bg-bluebook-red-highlight;
}

/* Highlights colors for shared passage content */
.bluebook-player
  :global(.anchor-has-leader .lrn-annotations-color--bluebook-red) {
  @apply bg-bluebook-shared-passage-red-highlight;
}

/* Active and Hover Colors */
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--hover
  ) {
  @apply !bg-bluebook-red-highlight-selected;
}

/* Red highlight combinations */
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-yellow
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-yellow
  ) {
  @apply !bg-bluebook-red-highlight-selected;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--hover
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
  ) {
  @apply !bg-bluebook-red-highlight-selected;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-blue
  ) {
  @apply !bg-bluebook-red-highlight-selected;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--hover
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
  ) {
  @apply !bg-bluebook-red-highlight-selected;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
  ) {
  @apply !bg-bluebook-red-highlight-selected;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-red
  ) {
  @apply !bg-bluebook-red-highlight-selected;
}
