.bluebook-player :global(.notes-column .notes-list) {
  @apply flex flex-col gap-[13px] mt-[27px] w-full overflow-auto;
  max-height: calc(
    var(--doc-height) - var(--header-height) - var(--footer-height) -
      var(--impersonating-header-height) - var(--teacher-preview-header-height) -
      var(--modal-margins)
  );
}

.bluebook-player :global(.notes-column .notes-list .notes-item:last-child) {
  @apply mb-[7px];
}
.bluebook-player :global(.notes-column .notes-list .notes-item) {
  @apply flex flex-col rounded-2xl cursor-pointer ml-[16px] mr-[16px] border-[1px];
}

.bluebook-player
  :global(.notes-column .notes-list .notes-item.notes-item-active) {
  @apply ml-0 mr-[32px] border-[2px];
}

.bluebook-player
  :global(.notes-column .notes-list .notes-item .notes-item-header) {
  @apply flex justify-between items-center gap-3 p-2 rounded-t-2xl;
}

.bluebook-player
  :global(.notes-column .notes-list .notes-item .notes-item-content) {
  @apply p-2 bg-white rounded-b-2xl;
}

.bluebook-player
  :global(.notes-column .notes-list .notes-item .notes-item-textarea) {
  @apply w-full h-auto resize-none overflow-hidden min-h-auto border-0 focus:!ring-0;
}
