.bluebook-player
  :global(
    .learnosity-item
      span.sound_script
      p:has(div.lrn_audioplayer):first-child
      div.lrn_audioplayer
  ),
.bluebook-player
  :global(
    .learnosity-item
      span.sound_script:not(:has(p)):has(div.lrn_audioplayer)
      div.lrn_audioplayer
  ) {
  padding-top: 114.8px;
}
