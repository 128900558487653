.bluebook-player:global(.bluebook-is-mobile .bluebook-player-inner-container) {
  height: calc(100% - var(--header-height) - var(--footer-height));
  overflow: auto;
}

.bluebook-player:global(.bluebook-is-mobile .lrn .app-layout .row) {
  @apply m-0;
}

.bluebook-player:global(
    .bluebook-is-mobile .lrn-assess .learnosity-item .lrn-assess-content.content
  ) {
  @apply px-0 pt-[11px];
}

.bluebook-player:global(.bluebook-is-mobile .col-xs-6),
.bluebook-player:global(.bluebook-is-mobile .col-xs-12) {
  @apply px-[10px];
}

.bluebook-player:global(.bluebook-is-mobile .bb-active-content) {
  @apply visible;
}

.bluebook-player:global(.bluebook-is-mobile .bb-inactive-content) {
  @apply hidden;
}

.bluebook-player:global(.bluebook-is-mobile .col-xs-6) {
  @apply w-full;
}

.bluebook-player:global(.bluebook-is-mobile .lrn-viewport) {
  @apply !overflow-visible;
}

.bluebook-player:global(.bluebook-is-mobile .lrn-scrollbar) {
  @apply hidden;
}

.bluebook-player:global(.bluebook-is-mobile .standalone_image img) {
  margin-left: auto !important;
}

.bluebook-player:global(.bluebook-is-mobile .poetry .verse) {
  margin-left: auto !important;
}

.bluebook-player:global(.bluebook-is-mobile .poetry .author) {
  margin-left: auto !important;
}
