/* Default highlights colors */
.bluebook-player :global(.lrn-annotations-color--bluebook-yellow) {
  @apply bg-bluebook-yellow-highlight;
}

/* Highlights colors for shared passage content */
.bluebook-player
  :global(.anchor-has-leader .lrn-annotations-color--bluebook-yellow) {
  @apply bg-bluebook-shared-passage-yellow-highlight;
}

/* Active and Hover Colors */
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--hover
  ) {
  @apply !bg-bluebook-yellow-highlight-selected;
}

/* Yellow highlight combinations */
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow
  ) {
  @apply bg-bluebook-green-highlight;
}

.bluebook-player
  :global(
    .anchor-has-leader
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .anchor-has-leader
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow
  ) {
  @apply bg-bluebook-shared-passage-yellow-blue-highlight;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red .lrn-annotations-color--bluebook-yellow
  ) {
  @apply bg-bluebook-orange-highlight;
}

.bluebook-player
  :global(
    .anchor-has-leader
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .anchor-has-leader
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow
  ) {
  @apply bg-bluebook-shared-passage-yellow-red-highlight;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-blue
  ) {
  @apply !bg-bluebook-yellow-highlight-selected;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-red
  ) {
  @apply !bg-bluebook-yellow-highlight-selected;
}
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--hover
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
  ) {
  @apply !bg-bluebook-yellow-highlight-selected;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--hover
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
  ) {
  @apply !bg-bluebook-yellow-highlight-selected;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
  ) {
  @apply !bg-bluebook-yellow-highlight-selected;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-yellow
  ) {
  @apply !bg-bluebook-yellow-highlight-selected;
}
