@import './Base.css';
@import './Components.css';
@import './Utilities.css';
@import './Screens.css';
@import './PerformanceColors.css';

*,
*::before,
*::after {
  @apply box-border border-solid border-0;
}

.chameleon-html *:focus,
.chameleon-body *:focus {
  @apply shadow-none;
}

.chameleon-html {
  @apply text-core;
  @apply bg-white;
}

.chameleon-body {
  @apply font-content;
  @apply text-default;
  @apply text-gray-900;
}

.chameleon-html .chameleon-body {
  @apply p-0;
}

.chameleon-html,
.chameleon-body,
.chameleon-body #root,
.chameleon-body #root > div {
  @apply w-full h-auto min-h-screen;
}

/* Learnosity data frames, alerts create window scrollbar because of styles above ^ */
body > [aria-live='assertive'],
body > .x-origin-frame {
  position: fixed !important;
  left: -10000px !important;
}

@layer base {
  a {
    @apply text-blue-700;
  }
}

.chameleon-html *:focus-visible {
  @apply outline-none;
  @apply ring-4 ring-blue-600 ring-opacity-20 !important;
}

@layer components {
  .border-left {
    @apply border-0 border-l border-solid border-gray-300;
  }

  .border-top {
    @apply border-0 border-t border-solid border-gray-300;
  }

  .border-bottom {
    @apply border-0 border-b border-solid border-gray-300;
  }

  .border-right {
    @apply border-0 border-r border-solid border-gray-300;
  }

  .html-overflow-hidden {
    @apply overflow-hidden;
  }
}
