.table_columns {
  @apply py-2 !important;
}

.table_columns_chunky {
  @apply py-4 !important;
}

.table_cell {
  /**
  * Using !important until we remove tailwind config important: '.chameleon',
  * that is overriding the border class
  */
  @apply text-14 !important;
}

.first_row {
  position: relative;
  bottom: -5px;
}

.table_cell.custom_cell {
  /**
  * Using !important until we remove tailwind config important: '.chameleon',
  * that is overriding the border class
  */
  @apply text-default;
  @apply font-normal !important;
}
.table_cell.light_cell {
  @apply text-gray-700 text-14;
}

.table_cell.title,
.table_cell.class {
  @apply text-14;
}

.icon {
  @apply items-center;
}

.retired_cell [aria-live='assertive'] {
  @apply left-0 relative !important;
}
