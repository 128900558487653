.bluebook-player :global(.lrn-annotations-underline--bluebook-solid) {
  @apply underline decoration-solid;
}

.bluebook-player :global(.lrn-annotations-underline--bluebook-dashed) {
  @apply underline decoration-dashed;
}

.bluebook-player :global(.lrn-annotations-underline--bluebook-dotted) {
  @apply underline decoration-dotted;
}
