.fabContainer {
  @apply fixed;
}

.fabItem {
  @apply w-20 h-20 bg-white mt-2 mb-0 mx-auto rounded-full flex justify-center items-center shadow-lg border-0
    transition-all;
}

.fabToggle.open {
  @apply bg-gray-900 mt-8;
}
