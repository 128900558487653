.bluebook-player :global(.two-columns-wrapper) {
  @apply flex justify-center;
}

.bluebook-player :global(.two-columns) {
  @apply relative w-[calc(50%-2px)];
}

.bluebook-player:global(.bluebook-is-desktop .col-xs-6 .lrn-viewport),
.bluebook-player:global(.bluebook-is-desktop .col-sm-6 .lrn-viewport) {
  @apply !overflow-visible;
}

.bluebook-player:global(.bluebook-is-desktop .lrn_audioplayer) {
  padding-top: 50px;
}

.bluebook-player :global(.custom-content-width),
.bluebook-player:global(
    .bluebook-is-desktop .crossout-container.is-above-widgets
  ) {
  @apply min-w-[240px] max-w-[664px] !mx-auto;
}

.bluebook-player {
  --expand-buttons-height: 45px;
  --header-height: 81px;
  --footer-height: 80px;
  --lrn-assess-content-padding: 30px;
  /* Disabling next line for stylelint because calc() does not accept 0 without unit */
  /* stylelint-disable-next-line length-zero-no-unit */
  --teacher-preview-header-height: 0px;
  /* stylelint-disable-next-line length-zero-no-unit */
  --impersonating-header-height: 0px;
  /* stylelint-disable-next-line length-zero-no-unit */
  --modal-margins: 0px;
}

.bluebook-player:global(.bluebook-is-impersonating) {
  --impersonating-header-height: 52px;
}

.bluebook-player:global(.bluebook-is-mobile:has(.check-answer-button)) {
  --footer-height: 105px;
}

.bluebook-player:global(.bluebook-is-mobile) {
  --header-height: 47px;
}

.bluebook-player:global(.bluebook-is-teacher-preview) {
  --teacher-preview-header-height: 70px;
  max-width: calc(100% - 52px);
}

.bluebook-player:global(.bluebook-is-teacher-preview.with-charm-bracelet) {
  --charm-bracelet-height: 52px;
  --teacher-preview-header-height: calc(70px + var(--charm-bracelet-height));
  @apply mt-[var(--charm-bracelet-height)];
}

.bluebook-player:global(.bluebook-is-modal) {
  /* Space around modal and modal header */
  --modal-margins: calc(calc(var(--doc-height) / 20) / 2) - 48px;
}

.bluebook-player:global(
    .bluebook-is-desktop
      .col-xs-12
      .lrn-overview
      [data-lrn-widget-type='feature']
      .lrn_sharedpassage
  ),
.bluebook-player:global(
    .bluebook-is-desktop
      .col-xs-12
      [data-lrn-widget-type='question']
      .lrn_widget
  ) {
  @apply mb-0;
}

.bluebook-player :global(.height-passage) {
  max-height: calc(
    var(--doc-height) - var(--header-height) - var(--footer-height) -
      var(--lrn-assess-content-padding) - var(--impersonating-header-height) -
      var(--teacher-preview-header-height) - var(--modal-margins)
  ) !important;
}

.bluebook-player:global(.bluebook-is-desktop .col-xs-6.two-columns.left-column),
.bluebook-player:global(
    .bluebook-is-desktop .col-xs-6.two-columns.right-column
  ) {
  height: calc(
    var(--doc-height) - var(--header-height) - var(--footer-height) -
      var(--lrn-assess-content-padding) - var(--impersonating-header-height) -
      var(--teacher-preview-header-height) - var(--modal-margins)
  );
  @apply overflow-auto;
}

.bluebook-player:global(.bluebook-is-desktop .two-columns:not(.col-xs-6)) {
  height: calc(
    var(--doc-height) - var(--header-height) - var(--footer-height) -
      var(--lrn-assess-content-padding) - var(--impersonating-header-height) -
      var(--teacher-preview-header-height) - var(--modal-margins)
  );
  @apply overflow-auto;
}

.bluebook-player:global(.bluebook-is-desktop .two-columns.col-xs-6) {
  @apply p-0;
}

.bluebook-player :global(.two-columns > .crossout-padding) {
  @apply !px-16;
}

/*
Make space for between column gutter/divider
Each column width is decreased with 2px to allow the 4px gutter stay between
*/
.bluebook-player:global(.bluebook-is-desktop .lrn .col-xs-6),
.bluebook-player:global(.bluebook-is-desktop .lrn .col-sm-6) {
  width: calc(50% - 2px);
}

.bluebook-player:global(.bluebook-is-desktop .col-xs-6 + .columns-gutter) {
  height: calc(
    var(--doc-height) - var(--header-height) - var(--footer-height) -
      var(--lrn-assess-content-padding) - var(--impersonating-header-height) -
      var(--teacher-preview-header-height) - var(--modal-margins)
  ) !important;
}

.bluebook-player:global(
    .bluebook-is-desktop .with-multiple-answers.one-column:has(.col-xs-6) .row
  ),
.bluebook-player:global(
    .bluebook-is-desktop .with-multiple-answers.one-column:has(.col-sm-6) .row
  ) {
  overflow: auto;
  max-height: calc(
    var(--doc-height) - var(--header-height) - var(--footer-height) -
      var(--lrn-assess-content-padding) - var(--impersonating-header-height) -
      var(--teacher-preview-header-height) - var(--modal-margins)
  );
}

.bluebook-player:global(
    .bluebook-is-desktop .with-multiple-answers.one-column .col-xs-6
  ),
.bluebook-player:global(
    .bluebook-is-desktop .with-multiple-answers.one-column .col-sm-6
  ) {
  @apply mx-auto float-none;
}

.bluebook-player :global(.custom-overflow) {
  @apply overflow-auto !mr-0 !ml-0;
  height: calc(
    var(--doc-height) - var(--header-height) - var(--footer-height) -
      var(--lrn-assess-content-padding) - var(--impersonating-header-height) -
      var(--teacher-preview-header-height) - var(--modal-margins)
  ) !important;
}

/* Removes the scroll from mathjax assistive cloned formula */
.bluebook-player:global(.bluebook-is-desktop mjx-container) {
  @apply inline-block;
}
.bluebook-player:global(.bluebook-is-desktop mjx-assistive-mml) {
  @apply right-0 bottom-0;
}

/* Handles two native columns when col-xs-6 is replaced by col-sm-6*/
.bluebook-player:global(.bluebook-is-desktop .col-xs-12.col-sm-6) {
  @apply p-0;
}

.bluebook-player:global(.bluebook-is-desktop .col-xs-12 ~ .columns-gutter) {
  height: calc(
    var(--doc-height) - var(--header-height) - var(--footer-height) -
      var(--lrn-assess-content-padding) - var(--impersonating-header-height) -
      var(--teacher-preview-header-height) - var(--modal-margins)
  );
}

.bluebook-player:global(
    .bluebook-is-desktop .col-xs-12.col-sm-6 div[style*='height']
  ) {
  @apply !h-auto;
}

.bluebook-player:global(
    .bluebook-is-desktop
      .with-multiple-answers
      .col-xs-12
      [data-lrn-widget-type='question']
  ),
.bluebook-player:global(
    .bluebook-is-desktop .with-multiple-answers .col-xs-12 .crossout-container
  ) {
  @apply !w-full;
}
