.modal-wrapper p {
  @apply leading-normal;
}

body[class*='overflow-hidden--'] {
  @apply overflow-hidden;
}

.bluebook-impersonating-modal {
  top: 52px !important;
  height: calc(var(--doc-height) - 52px) !important;
}

.bluebook-impersonating-modal-content {
  max-height: calc((var(--doc-height) * 0.95) - 52px);
}
