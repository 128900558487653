.bluebook-player:global(
    .with-annotation.can-annotate .bluebook-player-inner-container
  ),
.bluebook-player:global(
    .with-annotation.can-annotate .lrn-annotations-texthighlight-marking--hover
  ) {
  cursor:
    url(chameleon/packages/blue-book-player/cursors/annotate-selectable.svg) 2 8,
    auto;
}

.bluebook-player:global(.with-annotation.can-annotate .crossout-container) {
  cursor: auto;
}

.bluebook-player:global(
    .with-annotation.can-annotate .bluebook-player-inner-container img
  ) {
  cursor: url(chameleon/packages/blue-book-player/cursors/annotate-denied.svg),
    auto;
}
