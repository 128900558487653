@import '/highlightYellow';
@import '/highlightBlue';
@import '/highlightRed';
@import '/highlightUnderline';

/* All three colors combinations */
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow
  ) {
  @apply bg-bluebook-brown-highlight;
}

.bluebook-player
  :global(
    .anchor-has-leader
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .anchor-has-leader
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .anchor-has-leader
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .anchor-has-leader
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow
  ),
.bluebook-player
  :global(
    .anchor-has-leader
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .anchor-has-leader
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow
  ) {
  @apply bg-bluebook-shared-passage-yellow-blue-red-highlight;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--hover
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-yellow
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-yellow
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--hover
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
  ) {
  @apply !bg-bluebook-red-highlight-selected;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--hover
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--active
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-yellow
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-yellow
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow
  ) {
  @apply !bg-bluebook-blue-highlight-selected;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--hover
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--hover
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
  ) {
  @apply !bg-bluebook-yellow-highlight-selected;
}

/* Same colors combinations */
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-yellow
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-yellow
      .lrn-annotations-color--bluebook-yellow.lrn-annotations-texthighlight-marking--active
  ) {
  @apply !bg-bluebook-yellow-highlight-selected;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-blue
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-blue
      .lrn-annotations-color--bluebook-blue.lrn-annotations-texthighlight-marking--active
  ) {
  @apply !bg-bluebook-blue-highlight-selected;
}

.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--hover
      .lrn-annotations-color--bluebook-red
  ),
.bluebook-player
  :global(
    .lrn-annotations-color--bluebook-red
      .lrn-annotations-color--bluebook-red.lrn-annotations-texthighlight-marking--active
  ) {
  @apply !bg-bluebook-red-highlight-selected;
}
