@media (min-width: 1109px) {
  .popover-tooltip {
    @apply absolute top-[10px] right-[10px];
  }
}

@media (max-width: 1109px) {
  .popover-tooltip {
    @apply inline-block relative top-0 z-10;
  }
}

.dl-related-pdfs {
  @apply pl-4;
}

/* Styling for SVG paths within .popover-tooltip */
.popover-tooltip :global(svg path) {
  fill: var(--color-blue-600);
}
