@import url('./css/mcq.css');
@import url('./css/footer.css');
@import url('./css/columns.css');
@import url('./css/annotation.css');
@import url('./css/cursors.css');
@import url('./css/audioplayer.css');
@import url('./css/mobile.css');
@import url('./css/fileupload.css');
@import url('./css/modal.css');
@import url('./css/notes.css');
@import url('./css/anchors.css');
@import url('./css/sharedpassage.css');
@import url('./css/scrollbar.css');

.bluebook-player {
  @apply z-10;
}

.bluebook-player:global(
    .bluebook-is-teacher-preview:has(+ .question-details-expanded)
  ) {
  @apply max-w-[70%];
}

.bluebook-player :global(.lrn-region-group.lrn-group-top) {
  @apply !hidden;
}

.bluebook-player
  :global(.bluebook-player-inner-container .cb-learnosity-player) {
  @apply m-0;
}

.bluebook-player :global(.bluebook-player-inner-container .lrn .app-layout) {
  width: unset;
}

.bluebook-player
  :global(.bluebook-player-inner-container .lrn .app-layout .lrn-group-middle),
.bluebook-player
  :global(
    .bluebook-player-inner-container
      .lrn
      .app-layout
      .lrn-group-middle
      .lrn-region.items.lrn-items-region
  ) {
  @apply h-full;
}

.bluebook-player:global(
    .bluebook-is-desktop .bluebook-player-inner-container .lrn .app-layout .row
  ) {
  @apply -mr-[15px];
}

.bluebook-player :global(.lrn_widget .lrn_texteditor_editable) {
  border: 1px solid var(--color-bluebook-gray5) !important;
  margin-top: 0 !important;
  transition: none !important;
}

.bluebook-player :global(.wysihtml-editor:focus),
.bluebook-player :global(.wysihtml-editor:focus-visible) {
  border: 1px solid var(--color-bluebook-blue) !important;
  outline: none !important;
  box-shadow: none !important;
}

.bluebook-player :global(.lrn-wysihtml-toolbar > .lrn_btn:focus) {
  box-shadow: none !important;
}

.bluebook-player
  :global(
    div:not(.lrn-fileupload-statusbar-outlet) > div > .lrn-toolbar-footer
  ) {
  @apply hidden;
}

.bluebook-player :global(.lrn-toolbar:not(:has(.lrn-fileupload-upload-btn))) {
  @apply !bg-white;
}

.bluebook-player
  :global(.lrn_response_input_wrapper:not(:has(> .lrn-fileupload))) {
  border: none;
  margin-bottom: 16px;
}

.bluebook-player
  :global(div:not(.lrn-fileupload-toolbar-outlet) > div > .lrn-toolbar) {
  display: none;
}

.bluebook-player :global(.cb-learnosity-player:has(+ .bluebook-review-page)) {
  @apply hidden;
}

.bluebook-player :global(.bluebook-player-inner-container) {
  max-height: calc(
    var(--doc-height) - var(--header-height) - var(--footer-height) -
      var(--teacher-preview-header-height) - var(--modal-margins) -
      var(--impersonating-header-height)
  );
}

.bluebook-player
  :global(
    .lrn_texteditor_editable.lrn_editor_area.lrn_longtextinput:has(
        div[data-lrn-wysihtml-widget='codeEditorWidget']
      )
  ) {
  padding: 0;
}

.bluebook-player
  :global(p:has(+ div[data-lrn-wysihtml-widget='codeEditorWidget'])) {
  display: none;
}

.bluebook-player :global(div[data-lrn-wysihtml-widget='codeEditorWidget'] + p) {
  display: none;
}

.bluebook-player :global(.CodeMirror-gutter-wrapper) {
  display: none;
}

.bluebook-player :global(.CodeMirror-gutters) {
  display: none;
}

.bluebook-player :global(.CodeMirror.cm-s-default) {
  margin-bottom: 0 !important;
}

.bluebook-player :global(.CodeMirror-lines) {
  min-height: 80px !important;
  padding: 1rem !important;
}

.bluebook-player :global(div.learnosity-item:not(.item-is-loaded)::before) {
  content: ' ';
  @apply bg-white absolute left-0 top-0 w-full h-full z-50;
}

.bluebook-player :global(div.learnosity-item:not(.item-is-loaded)::after) {
  animation: 'spin' 0.75s linear infinite;
  content: url(chameleon/packages/blue-book-player/library/icon-list/raw-icons/loading-spinner.svg);
  @apply absolute z-max top-1/2 left-1/2 transform -translate-x-1/2 -mt-[40px];
}

.bluebook-player :global(.one-column[style*='visibility: visible']) {
  @apply transition-[padding] ease-in-out duration-1000;
}

.bluebook-player:global(.with-calculator .one-column) {
  @apply !pl-[400px];
}
