.performanceBarMinWidth {
  min-width: 200px;
}

@media (max-width: 567px) {
  .performanceBarMinWidth {
    min-width: 134px;
  }
}

@media (max-width: 360px) {
  .performanceBarMinWidth {
    min-width: 113px;
  }
}
