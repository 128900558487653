.first-level-item span {
  @apply pt-4 w-full;
}

.button:hover .content {
  @apply hover:bg-blue-500 hover:bg-opacity-10;
}

.button.is-active .content {
  @apply text-blue-600 bg-white hover:bg-white;
}

.first-level-item div:focus {
  outline: 2px solid rgba(255, 255, 255, 0.527);
  outline-offset: -3px;
}

.first-level-item-active {
  text-decoration: underline;
}
