.with-crossout
  :global(.bluebook-player-inner-container .learnosity-item .lrn_mcqgroup > *) {
  width: calc(100% - 55px);
  min-width: calc(100% - 55px) !important;
  @apply relative;
}

.with-crossout :global(.lrn_mcqgroup li.lrn-mcq-option[data-masked='true']) {
  @apply after:absolute after:top-[calc(50%+6px)] after:h-[2px] after:w-[calc(100%+8px)] after:content-[''] after:bg-gray-900 after:block after:-left-[4px];
}

.with-crossout
  :global(li.lrn-mcq-option.lrn_selected[data-masked='true'] .lrn-input:focus),
.with-crossout
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_mcqgroup.lrn_mcqgroup-horizontal
      li.lrn-mcq-option[data-masked='true']
      .lrn-input:focus
      + .lrn-label
  ),
.with-crossout
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_mcqgroup.lrn_mcqgroup-vertical
      li.lrn-mcq-option[data-masked='true']
      .lrn-input:focus
      + .lrn-label
  ) {
  @apply outline-none shadow-none;
}

.with-crossout
  :global(.lrn_mcqgroup li.lrn-mcq-option[data-masked='true'])
  label {
  @apply opacity-50;
}

.with-crossout
  :global(.lrn_mcqgroup li.lrn-mcq-option[data-masked='true'])
  label {
  @apply before:relative before:h-[2px] before:w-[30px] before:content-[''] before:bg-gray-900 before:block before:!top-[22px] before:left-[8px];
}

.crossout-is-enabled {
  --current-background-color: var(--color-bluebook-blue);
  --current-text-color: var(--color-white);
}

.crossout-is-disabled {
  --current-background-color: var(--color-white);
  --current-text-color: var(--color-gray-900);
}

.with-crossout :global(li.lrn-mcq-option.lrn_selected[data-masked='true']) {
  @apply bg-none;
}

.without-crossout :global(.lrn .lrn-mask),
.with-crossout :global(.lrn .lrn-mask) {
  @apply outline-none pointer-events-none;
}

.with-crossout :global(.lrn-mask svg) {
  @apply hidden;
}

.without-crossout
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup.override-selection
      .lrn-mcq-option.lrn_selected
      .lrn-label
  ),
.with-crossout
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup.override-selection
      .lrn-mcq-option.lrn_selected
      .lrn-label
  ) {
  @apply border border-bluebook-gray1 rounded-2xl mt-[15px] py-[12px] pr-[24px] pl-[56px] leading-[1.5em] min-h-0 !shadow-none outline-none;
}

.without-crossout
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup.override-selection
      .lrn-mcq-option.lrn_selected
      .lrn-label::after
  ),
.with-crossout
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup.override-selection
      .lrn-mcq-option.lrn_selected
      .lrn-label::after
  ) {
  @apply bg-white text-bluebook-gray1 border-2 border-bluebook-gray1 w-[25px] h-[25px] font-semibold top-[9px] left-[10px];
}
