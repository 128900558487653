.default-tier {
  @apply bg-gray-600;
}

.default-tier-border {
  @apply border-gray-600;
}

.tier1,
.tier-developing {
  @apply bg-yellow-400;
}

.tier1-text,
.tier-developing-text {
  @apply text-yellow-400;
}

.tier1-border,
.tier-developing-border {
  @apply border-yellow-400;
}

.tier2,
.tier-approaching {
  @apply bg-yellow-200;
}

.tier2-text,
.tier-approaching-text {
  @apply text-yellow-200;
}

.tier2-border,
.tier-approaching-border {
  @apply border-yellow-200;
}

.tier3,
.tier-progressing {
  @apply bg-green-200;
}

.tier3-text,
.tier-progressing-text {
  @apply text-green-200;
}

.tier3-border,
.tier-progressing-border {
  @apply border-green-200;
}

.tier4,
.tier-achieving {
  @apply bg-green-400;
}

.tier4-text,
.tier-achieving-text {
  @apply text-green-400;
}

.tier4-border,
.tier-achieving-border {
  @apply border-green-400;
}
