/* global audio styling for learnosity bar player
https://chromium.googlesource.com/chromium/blink/+/72fef91ac1ef679207f51def8133b336a6f6588f/Source/core/css/mediaControls.css?autodive=0%2F%2F%2F
https://stackoverflow.com/questions/4126708/is-it-possible-to-style-html5-audio-tag */

.bluebook-player :global(audio) {
  @apply bg-gray-300;
  @apply border;
  @apply border-gray-400;
  height: 3.1em;
  min-height: 2.85714em;
  max-width: 33.5em;
  width: 33.5em;
  min-width: 8.57143em;
}

.bluebook-player :global(audio:focus) {
  outline: None;
}

.bluebook-player :global(audio::-webkit-media-controls-panel) {
  @apply bg-gray-300;
  @apply border;
  border-radius: 0;
  /* The duration is also specified in MediaControlElements.cpp and LayoutTests/media/media-controls.js */
  transition: opacity 0.3s;
}

.bluebook-player :global(audio::-webkit-media-controls-play-button) {
  @apply border-r;
  @apply border-gray-400;
}
