.bluebook-player :global(::-webkit-scrollbar) {
  @apply w-[10px] h-[10px] float-left;
}

.bluebook-player :global(::-webkit-scrollbar-thumb) {
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2);
  @apply rounded-[10px] bg-bluebook-gray3;
}

.bluebook-player :global(::-webkit-scrollbar-track) {
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.3);
  @apply rounded-[10px] ml-[4px] mr-[4px] bg-white;
}
