.left-column-expander,
.right-column-expander {
  @apply !p-0 z-10;
}

.left-column-expander:global(:has(~ .notes-column)) {
  @apply absolute left-[calc(100%-45px)];
}

.left-column-expander :global(button) {
  @apply sticky top-0;
  left: calc(100% - 46px);
}

.right-column-expander :global(button) {
  @apply sticky left-[14px] top-0;
}
